@use "src/styles/globals.scss" as *;

.error-placeholder {
  @include centered-column;
  align-items: center;
  border-radius: 5px;
  height: 100%;
  padding: 48px 16px;
  width: 100%;

  &-image {
    margin-bottom: 16px;

    img {
      display: block;
      height: 78px;
      width: 100px;
    }
  }

  &-text {
    @include text-roboto-body-500;
    max-width: 165px;
    text-align: center;
  }
}
