@use "src/styles/globals.scss" as *;

.chip {
  @include text-roboto-body-400;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 40px;

  background-color: var(--color-white);
  color: var(--color-black);

  &.green {
    background-color: var(--color-success-15);
    color: var(--color-success-100);
  }

  &.orange {
    background-color: var(--color-alert-30);
    color: var(--color-alert-100);
  }

  &.red {
    background-color: var(--color-error-30);
    color: var(--color-error-100);
  }
}
