@use "src/styles/globals.scss" as *;

.governor-header {
  @include centered-column;
  background-color: var(--color-gray-950);
  border-radius: 16px;
  gap: 20px;
  margin-bottom: 40px;
  padding: 20px 20px 24px 20px;

  @include desktop {
    @include centered-row;
    justify-content: space-between;
    padding: 28px 32px;
  }

  &-title {
    @include centered-row;
    @include text-roboto-subheading-500;
    color: var(--color-white);
    gap: 8px;
  }

  &-description {
    @include text-roboto-body-400;
    color: var(--color-gray-400);
    text-wrap: pretty;
    max-width: 576px;

    &-link {
      @include button-ghost;
      display: inline;
    }
  }
}
