// Globals

@mixin centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin centered-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@function withOpacity($color, $opacity) {
  @return linear-gradient(
    to bottom,
    $color calc(($opacity - 1) * 10000%),
    transparent calc($opacity * 10000%)
  );
}

// Breakpoints

$tabletBreakpoint: 768px;
$desktopBreakpoint: 1024px;
$bigDesktopBreakpoint: 1440px;

@mixin tablet {
  @media only screen and (min-width: $tabletBreakpoint) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktopBreakpoint) {
    @content;
  }
}

@mixin bigDesktop {
  @media only screen and (min-width: $bigDesktopBreakpoint) {
    @content;
  }
}

// Typography

@mixin text-heading4 {
  font-family: "Roboto Mono", "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.06em;

  @include desktop {
    font-size: 24px;
  }
}

@mixin text-heading5 {
  font-family: "Roboto Mono", "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 20px;
}

@mixin text-heading6 {
  font-family: "Roboto Mono", "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 16px;
}

@mixin text-roboto-heading-500 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 24px;
}

@mixin text-roboto-subheading-500 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 24px;
}

@mixin text-roboto-body-large-400 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 24px;
}

@mixin text-roboto-body-500 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 16px;
}

@mixin text-roboto-body-400 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 20px;
}

// Buttons

@mixin button-primary {
  @include centered-row;
  @include text-roboto-body-500;
  background: var(--color-plum);
  border-radius: 27px;
  border: none;
  color: var(--color-black);
  cursor: pointer;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;

  &:not(:disabled) {
    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
        var(--color-plum);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--color-plum);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  & > svg {
    transition: transform 0.2s ease;
  }
}

@mixin button-secondary {
  @include button-primary;
  background: var(--color-gray-900);
  color: var(--color-white);

  &:not(:disabled) {
    &:hover {
      background: var(--color-gray-800);
    }

    &:active {
      background: var(--color-gray-900);
    }
  }
}

@mixin button-tertiary {
  @include button-primary;
  background-color: var(--color-coral);

  &:not(:disabled) {
    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
        var(--color-coral);
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--color-coral);
    }
  }
}

@mixin button-secondary-non-interactable {
  @include centered-row;
  @include text-roboto-body-500;
  background: var(--color-gray-900);
  border-radius: 27px;
  border: none;
  color: var(--color-white);
  cursor: default;
  gap: 4px;
  height: 36px;
  padding: 0 12px;
  text-decoration: none;
  white-space: nowrap;
}

@mixin button-ghost {
  @include centered-row;
  @include text-roboto-body-400;
  background: transparent;
  border: none;
  color: var(--color-white);
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--color-gray-600);
  text-underline-offset: 3px;

  &:not(:disabled) {
    &:hover {
      text-decoration-color: var(--color-gray-400);
    }

    &:active {
      text-decoration-color: var(--color-gray-600);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
