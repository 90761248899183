@use "src/styles/globals.scss" as *;

.select {
  @include centered-row;
  height: 36px;
  width: 100%;
  white-space: nowrap;

  @include desktop {
    width: max-content;
  }

  &__control {
    @include button-secondary;
    justify-content: center;
    padding-right: 8px;
    width: 100%;

    @include desktop {
      width: max-content;
    }

    > .select__indicators {
      transition: transform 0.2s;
    }

    &--menu-is-open {
      background-color: var(--color-gray-800);

      > .select__indicators {
        transform: rotate(-180deg);
      }
    }

    & > .select__value-container {
      max-width: max-content;
    }
  }

  &__placeholder {
    color: var(--color-white-50);
  }

  &__indicators {
    @include centered-row;
    color: var(--color-white);
  }

  &__menu {
    margin-top: 8px;
    min-width: max-content;
    right: 0;

    &-notice--no-options {
      @include text-roboto-body-400;
      color: var(--color-white-50);
      padding: 8px;
    }
  }

  &__menu-list {
    @include text-roboto-body-500;
    background-color: var(--color-gray-900);
    border-radius: 12px;
    min-width: max-content;
    overflow: overlay;
    white-space: nowrap;

    &-scroll-button {
      @include centered-row;
      height: 20px;
      justify-content: center;
      text-align: center;
    }

    & > .search-container {
      margin-top: 16px;

      & > input {
        background-color: var(--color-gray-800);
        border: 1px solid var(--color-gray-800);
        border-radius: 24px;
        color: var(--color-white-50);
        padding: 8px 16px;
        width: 100%;
        z-index: 50;

        &:focus {
          outline: transparent;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      background-color: var(--color-white-20);
      border-radius: 8px;
      border: 4px solid transparent;
    }
  }

  &__option {
    @include text-roboto-body-400;
    @include centered-row;
    height: inherit;
    width: inherit;
    color: var(--color-white);
    padding: 10px 12px;
    cursor: pointer !important;
    user-select: none;
    border: 1px solid transparent;

    &:hover:not(.select__option--is-selected):not(.select__option--is-disabled) {
      background-color: var(--color-white-05);
    }

    &:focus-visible {
      outline: transparent;
      background-color: var(--color-white-05);
    }

    &--is-selected {
      background-color: var(--color-gray-800);
      color: var(--color-white);
    }

    &--is-disabled {
      color: var(--color-white-50);
      cursor: not-allowed !important;
    }
  }

  &.secondary {
    & .select__control {
      @include text-heading6;
      background-color: transparent;
      height: auto;
      min-height: unset;
      text-transform: uppercase;
    }
  }
}

.select-custom-option {
  @include centered-row;
  gap: 8px;
  justify-content: space-between;

  &-container {
    @include centered-row;
    gap: 8px;
  }

  &-text {
    @include centered-column;
    gap: 0px;

    &-subtitle {
      margin-left: 2px;
      color: var(--color-white-50);
      font-size: 12px;

      p {
        padding: 0;
        margin: 0;
        margin-top: 2px;
        line-height: 1.3;
      }
    }
  }
}

.select-searchable {
  position: relative;

  &-dropdown {
    @include button-secondary;
    padding-right: 8px;

    &.disabled {
      cursor: not-allowed;
    }

    &.open {
      & > svg {
        transform: rotate(-180deg);
      }
    }
  }

  &-menu {
    background-color: var(--color-gray-900);
    border-radius: 24px 24px 0 0;
    margin-top: 16px;
    min-width: max-content;
    padding: 16px 16px 24px 16px;
    position: absolute;
    width: 100%;
    z-index: 50;

    @include desktop {
      width: max-content;
    }

    & .select__value-container {
      max-width: none;
    }

    & .select__control {
      font-size: 16px;

      @include desktop {
        @include text-roboto-body-400;
      }

      &::after {
        background-image: url("/src/assets/selectSearchIcon.svg");
        background-size: cover;
        border-radius: 50%;
        content: "";
        height: 20px;
        position: absolute;
        left: 8px;
        width: 20px;
      }

      & > .select__value-container {
        padding-left: 20px;
      }

      & > svg {
        color: var(--color-gray-400);
      }
    }
  }
}
