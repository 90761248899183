@use "src/styles/globals.scss" as *;

.only-mainnet {
  margin-bottom: 80px;

  &-text {
    @include text-heading5;
    color: var(--color-gray-200);

    &-link {
      @include button-ghost;
      @include text-heading5;
      display: inline-block;
      color: var(--color-coral);
      text-decoration-color: var(--color-coral-20);

      &:not(:disabled) {
        &:hover {
          text-decoration-color: var(--color-coral-40);
        }

        &:active {
          text-decoration-color: var(--color-coral-20);
        }
      }
    }
  }
}

.error-page {
  display: flex;
  justify-content: center;
  margin: auto 0;
  padding: 32px 0;

  @include desktop {
    padding: 96px 32px;

    &-bg-503 {
      background-repeat: no-repeat;
      background-position: calc(50% - 136px) center;
      background-image: url("/src/assets/bg-503.svg");
    }

    &-bg-general {
      background-repeat: no-repeat;
      background-position: center 0;
      background-image: url("/src/assets/bg-gerenal.svg");
    }
  }

  @include bigDesktop {
    padding: 96px 200px;
  }

  &-bg-500 {
    background-repeat: no-repeat;
    background-position: calc(50% + 116px) calc(50% - 136px);
    background-image: url("/src/assets/bg-mobile-500.svg");

    @include desktop {
      background-position: center;
      background-image: url("/src/assets/bg-500.svg");
    }
  }

  &-container {
    background-color: var(--color-white-05);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 32px 24px;
    place-items: center;
    width: 100%;

    @include desktop {
      background-color: unset;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr;
      max-width: 1100px;
      padding: 0;
      place-items: start;
    }

    &-title {
      text-align: center;

      @include desktop {
        text-align: left;
        font-size: 40px;
      }

      &-400 {
        @include desktop {
          font-weight: 600;
        }
      }

      & span {
        display: block;
      }
    }

    &-image {
      grid-row: span 2;
      margin: 40px auto 0 auto;
      position: relative;

      @include desktop {
        margin: auto 0 auto auto;
      }

      &-text {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 24px;

        @include desktop {
          font-size: 32px;
        }

        & span {
          display: block;

          font-size: 40px;
        }
      }

      &-astronaut {
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 140px;

        @include desktop {
          right: 64px;
          top: 80px;
          width: 200px;
        }
      }

      &-400 {
        width: 156px;

        @include desktop {
          width: 285px;
        }
      }

      &-500 {
        width: 174px;

        @include desktop {
          width: 425px;
        }
      }

      &-502 {
        width: 174px;

        @include desktop {
          width: 312px;
        }
      }

      &-503 {
        width: 200px;

        @include desktop {
          width: 425px;
        }
      }

      &-general {
        width: 147px;

        @include desktop {
          width: 341px;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: space-between;
      margin-top: 36px;
      text-align: center;

      @include desktop {
        margin-top: 16px;
        text-align: left;
      }

      &-description {
        max-width: 324px;

        @include desktop {
          font-size: 20px;
          max-width: 424px;
        }

        &-400 {
          font-size: 16px;

          @include desktop {
            margin-top: 20px;
          }
        }

        &-500 {
          color: var(--color-plum);
        }

        & span {
          display: block;
          word-break: break-all;
        }

        & em {
          font-style: normal;
        }

        &-mobile {
          @include desktop {
            display: none;
          }
        }

        &-desktop {
          display: none;

          @include desktop {
            display: inline-block;
          }
        }

        & a {
          text-decoration: none;
          color: var(--color-lime);

          &:hover {
            text-decoration: underline;
          }
        }

        & .info-link {
          color: var(--color-lime);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-code {
        color: var(--color-plum-12);
        font-size: 20px;
        margin-top: 16px;

        @include desktop {
          margin-top: 42px;
        }
      }

      &-button {
        @include button-secondary;

        margin: 20px auto 0 auto;
        padding-left: 32px;
        padding-right: 32px;

        @include desktop {
          margin: 32px auto 0 0;
          padding-left: 56px;
          padding-right: 56px;
        }
      }
    }
  }
}
