@use "src/styles/globals.scss" as *;

.json-view {
  &-row {
    margin-left: 16px;
    position: relative;

    @include tablet {
      word-break: break-all;
    }
  }

  &-expandIcon {
    cursor: pointer;
    left: -18.2px;
    padding: 0 6.1px;
    position: absolute;

    &::before {
      border-color: transparent transparent transparent var(--color-white);
      border-style: solid;
      border-width: 4px 0 4px 6px;
      content: "";
      display: inline-block;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &-collapseIcon {
    cursor: pointer;
    left: -18.2px;
    padding: 0 5.1px;
    position: absolute;

    &::before {
      border-color: var(--color-white) transparent transparent transparent;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      content: "";
      cursor: pointer;
      display: inline-block;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &-collapsedContent {
    cursor: pointer;

    &::after {
      content: " ... ";
    }

    &:hover {
      filter: brightness(1.4);
    }
  }

  &-key {
    color: var(--color-gray-500);
    padding-right: 4px;
  }

  &-info {
    color: var(--color-coral);
  }

  &-number {
    color: var(--color-lime);
  }

  &-string {
    color: var(--color-white);
  }
}
