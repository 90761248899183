.status-badge {
  max-width: max-content;

  &-tooltip {
    font-size: 12px;
    line-height: normal;
    max-width: 172px;
    text-align: center;
  }

  &-status {
    min-width: max-content;
    text-transform: uppercase;

    &.progress,
    &.governor,
    &.pending {
      background-color: var(--color-alert-10);
      color: var(--color-alert-100);

      & > svg > path {
        stroke-width: 1;
      }
    }

    &.completed {
      background-color: var(--color-success-10);
      color: var(--color-success-100);

      & > svg > path {
        stroke-width: 1;
      }
    }

    &.emitted {
      background-color: var(--color-information-12);
      color: var(--color-information-100);

      & > svg > path {
        stroke-width: 1;
      }
    }
  }

  &-small {
    border-radius: 40px;
    margin: auto;

    &.progress,
    &.governor,
    &.pending {
      & > svg {
        color: var(--color-alert-100) !important;
      }
    }

    &.completed {
      & > svg {
        color: var(--color-success-100) !important;
      }
    }

    &.emitted {
      & > svg {
        color: var(--color-information-100) !important;
      }
    }
  }
}
