@use "src/styles/globals.scss" as *;

.chart-container {
  background-color: var(--color-gray-950);
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--color-gray-900);
  border-top: none;
  height: 390px;
  padding: 0 0 20px 0;
  position: relative;
  width: 100%;

  @include desktop {
    background-color: transparent;
    border: none;
    flex: 1.4;
    height: unset;
    overflow: hidden;
    padding: unset;
  }

  @include bigDesktop {
    flex: 1.2;
  }

  & > div:last-child {
    max-width: calc(100vw - 44px);
    min-height: 390px !important;

    @include tablet {
      max-width: calc(100vw - 132px);
    }

    @include desktop {
      min-height: 600px !important;
    }
  }

  .wormholescan-brand {
    bottom: 0;
    filter: grayscale(1);
    height: 34px;
    left: 0;
    margin: auto;
    min-height: unset;
    opacity: 0.2;
    padding-top: 40px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
    width: 217px;
    z-index: 10;
  }

  &-chain-icon img {
    height: 16px;
    width: 16px;

    @include tablet {
      height: 20px;
      width: 20px;
    }

    @include bigDesktop {
      height: 24px;
      width: 24px;
    }
  }

  &-tooltip {
    background-color: var(--color-gray-900);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style-type: none;
    min-width: 192px;
    padding: 12px;

    @include desktop {
      min-width: 264px;
      padding: 16px;
    }

    &.transfers {
      & > .volume {
        order: 3;
      }
    }

    &.is-testnet {
      & > .volume {
        display: none;
      }
    }

    &-item {
      @include centered-row;
      gap: 8px;
      justify-content: space-between;

      &:first-child {
        justify-content: flex-start;
        margin-bottom: 4px;
      }

      &-img {
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }

      &-label {
        @include text-roboto-body-400;
        color: var(--color-white);
      }

      &-chain {
        @include text-roboto-body-400;
        color: var(--color-gray-400);
      }

      &-volume {
        @include text-heading5;
        color: var(--color-white);
        font-family: "Roboto Mono", "Roboto", sans-serif !important;
      }

      &-txs {
        @include text-heading5;
        color: var(--color-white);
        font-family: "Roboto Mono", "Roboto", sans-serif !important;
      }
    }
  }

  &-xaxis-label {
    direction: rtl;
    display: none;
  }
}
