@use "src/styles/globals.scss" as *;

.footer {
  @include centered-column;
  gap: 32px;
  margin: 0 0 96px 0;

  @include tablet {
    margin: 0 64px 96px 64px;
  }

  @include desktop {
    gap: 86px;
    margin: 0 auto 58px auto;
    width: 100%;
  }

  &-container {
    display: grid;
    gap: 40px 0;
    grid-template-columns: auto auto;
    justify-content: space-between;
    width: 100%;

    @include desktop {
      @include centered-row;
      align-items: flex-start;
    }

    &-discord {
      @include centered-column;
      background-color: var(--color-gray-950);
      border-radius: 16px;
      color: var(--color-gray-600);
      gap: 10px;
      grid-column: span 2;
      padding: 16px;
      width: 100%;

      @include desktop {
        @include centered-row;
        border-radius: 20px;
      }

      &-text {
        @include text-roboto-body-large-400;
        color: var(--color-white);
        max-width: 240px;

        @include desktop {
          max-width: unset;
        }
      }

      &-links {
        @include centered-row;
        gap: 20px;
        margin-top: 14px;

        @include desktop {
          margin: 0 0 0 auto;
        }

        &-ds-btn {
          @include button-primary;
          padding: 0 12px 0 8px;

          & > svg {
            color: var(--color-black-60);
          }
        }

        &-x-btn {
          @include button-secondary;
          padding: 0 12px 0 8px;

          & > svg {
            color: var(--color-white-60);
          }
        }
      }
    }

    &-button {
      @include button-secondary;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin-left: auto;
      max-width: max-content;
      padding-right: 8px;

      @include desktop {
        margin-left: 0;
      }

      & > svg {
        color: var(--color-white-60);
      }
    }

    &-links {
      border-bottom: 1px solid var(--color-gray-950);
      display: grid;
      gap: 40px 0;
      grid-column: span 2;
      grid-template-columns: repeat(2, 1fr);
      padding-bottom: 32px;
      white-space: nowrap;
      width: 100%;

      @include desktop {
        border: none;
        grid-template-columns: repeat(3, auto);
        justify-content: space-between;
        padding-bottom: 0;
        width: 45%;
      }

      &-container {
        display: flex;
        flex-direction: column;

        & > div {
          @include centered-row;
          gap: 8px;
        }

        &-title {
          @include centered-row;
          @include text-heading6;
          color: var(--color-gray-400);
          margin-bottom: 24px;
          text-transform: uppercase;

          &-icon {
            font-size: 16px;
            margin-right: 6px;
          }
        }

        & > a {
          @include button-ghost;
          @include text-roboto-body-400;
          color: var(--color-gray-200);
          max-width: max-content;
          min-height: 25px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-build {
      @include centered-row;
      @include text-roboto-body-large-400;
      gap: 16px;
      justify-content: flex-end;

      &-text {
        color: var(--color-gray-400);
      }

      & > a {
        @include centered-row;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-copy {
      @include centered-row;
      color: var(--color-gray-600);
      flex-wrap: wrap;
      max-width: 220px;

      @include desktop {
        margin: auto 0;
        max-width: unset;
      }

      &-text {
        @include text-heading6;
        text-transform: uppercase;
        margin: 0 16px 0 6px;
      }

      & > a {
        @include button-ghost;
        @include text-roboto-body-400;
        color: var(--color-gray-200);
        max-width: max-content;
        min-height: 25px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  & .desktop {
    display: none;

    @include desktop {
      display: flex;
    }
  }
}
