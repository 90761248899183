@use "src/styles/globals.scss" as *;

.custom-checkbox {
  @include centered-row;
  border-radius: 5px;
  border: 2px solid var(--color-gray-800);
  color: var(--color-black);
  height: 20px;
  justify-content: center;
  width: 20px;

  &.checked {
    background-color: var(--color-plum);
    border: none;
  }
}
