@use "src/styles/globals.scss" as *;

.pagination {
  @include centered-row;
  gap: 4px;
  min-height: 36px;
  width: 100%;

  @include desktop {
    width: max-content;
  }

  &-pages {
    @include centered-row;
    gap: 4px;
    margin: 0 auto;
  }

  & > button,
  & > span,
  & > .pagination-pages > button,
  & > .pagination-pages > span {
    @include centered-row;
    @include text-roboto-body-500;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid var(--color-gray-900);
    color: var(--color-gray-400);
    cursor: pointer;
    justify-content: center;
    min-height: 36px;
    min-width: 36px;
    text-align: center;

    &:disabled {
      cursor: not-allowed;
    }

    &:not(.current):disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background-color: var(--color-white-05);
    }

    &:not(:disabled):active {
      background-color: var(--color-gray-900);
    }

    &.page {
      border: 1px solid transparent;
      color: var(--color-gray-400);
    }

    &.current {
      background-color: var(--color-gray-900);
      border: 1px solid transparent;
      color: var(--color-white);
    }
  }
}
