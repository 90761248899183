@use "src/styles/globals.scss" as *;

.toggle-group {
  @include centered-row;
  gap: 12px;
  max-width: max-content;
  overflow-x: auto;

  &-root {
    @include centered-row;
    background-color: var(--color-gray-900);
    border-radius: 24px;
    gap: 12px 6px;
  }

  &-item {
    @include button-secondary;
    background-color: transparent;
    color: var(--color-gray-400);

    &[data-state="on"] {
      @include button-secondary;
      background-color: var(--color-gray-800);
    }
  }

  &.secondary {
    & .toggle-group-root {
      border-radius: 12px;

      & .toggle-group-item {
        border-radius: 12px;
        padding: 6px;
      }
    }
  }
}
