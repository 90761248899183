@use "src/styles/globals.scss" as *;

.top-asset-list-row {
  height: 64px;
  font-size: 14px;
  color: var(--color-white);
  cursor: pointer;
  position: relative;

  @include desktop {
    border-bottom: 1px solid transparent;
    font-size: 16px;

    &:hover {
      background-color: var(--color-white-03);
    }
  }

  &-item {
    @include text-heading5;

    &-to-asset {
      @include text-roboto-body-500;
    }

    &-chevron {
      @include desktop {
        display: none;
      }
    }
  }

  & > td {
    height: 64px;
    padding: 0 8px;
    position: relative;
    text-align: end;

    @include tablet {
      padding: 0 16px;
    }

    @include desktop {
      padding: 0 8px;
    }

    @include bigDesktop {
      padding: 0 16px;
    }

    &:first-child {
      & > div {
        justify-content: center;
        color: var(--color-gray-400);
      }
    }

    &:nth-child(2) {
      & > div {
        justify-content: flex-start;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      & > div {
        justify-content: flex-end;
      }
    }

    & > div {
      @include centered-row;
      gap: 8px;

      @include desktop {
        gap: 12px;
      }

      & > .image {
        align-items: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        padding: 2.4px;
      }

      & > svg {
        color: var(--color-white-60);
        vertical-align: middle;
        transition: transform 0.2s;
      }
    }
  }

  &.active {
    @include desktop {
      background: linear-gradient(270deg, var(--color-plum-12) 65%, transparent 100%);
      background-attachment: fixed;
      border-bottom: 1px solid var(--color-white-05);
    }

    & > td {
      z-index: 1;

      & > div {
        & > svg {
          transform: rotate(-180deg);
        }
      }

      &::before {
        background: var(--color-gray-950);
        border-bottom: 1px solid var(--color-gray-900);
        border-top: 1px solid var(--color-gray-900);
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: -1;
      }

      &:first-child::before {
        background-color: var(--color-gray-950);
        border-left: 1px solid var(--color-gray-900);
        border-radius: 16px 0 0 0;
        border-top: 1px solid var(--color-gray-900);
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: -1;
      }

      &:last-child::before {
        background-color: var(--color-gray-950);
        border-radius: 0 16px 0 0;
        border-right: 1px solid var(--color-gray-900);
        border-top: 1px solid var(--color-gray-900);
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 100%;
        z-index: -1;
      }

      @include desktop {
        &::before,
        &:first-child::before,
        &:last-child::before {
          all: unset;
        }
      }
    }
  }
}
