@use "src/styles/globals.scss" as *;

.copy-to-clipboard {
  cursor: pointer;

  @include desktop {
    &:hover {
      filter: brightness(1.2);
    }
  }

  &-children {
    display: flex;
    gap: 8px;
    align-items: center;
    color: inherit;
  }
}
