@use "src/styles/globals.scss" as *;

.banner {
  @include centered-column;

  border-radius: 5px;
  gap: 16px;
  justify-content: center;
  margin-bottom: 32px;
  padding: 24px 16px;
  width: 100%;

  @include desktop {
    @include centered-row;

    margin-bottom: 0;
    margin-top: 24px;
    padding: 32px;
  }

  @include bigDesktop {
    margin-top: 32px;
  }

  &-warning {
    background-color: #995a341a;
    color: #fff2e9;

    .banner-content-description {
      max-width: 936px;
    }
  }

  &-error {
    background-color: #f520201a;
    color: #ffe3e9;

    .banner-content-description {
      max-width: 856px;
    }
  }

  &-success {
    background-color: #01bbac1a;
    color: #e1fffd;

    .banner-content-description {
      max-width: 816px;
    }
  }

  &-icon {
    margin-bottom: auto;
  }

  &-content {
    @include centered-column;
    gap: 24px;

    &-title {
      &-text {
        display: block;

        @include tablet {
          display: inline-block;
        }
      }

      &-hyphen {
        display: none;

        @include tablet {
          display: inline-block;
        }
      }

      &-span {
        color: #01bbac;
        display: block;

        @include tablet {
          display: inline-block;
        }
      }
    }

    &-description {
      @include centered-column;

      font-size: 14px;
      gap: 24px;
      /* font-weight: 100; */

      @include desktop {
        font-size: 16px;
        gap: 0;
      }

      &-span {
        font-size: 14px;

        @include desktop {
          font-size: 16px;
        }

        &-break {
          display: inline-block;
          margin-top: 24px;

          @include desktop {
            display: inline;
          }
        }

        &-warning {
          font-weight: 600;
          color: var(--color-alert-100);
        }
      }
    }
  }
}
