@use "src/styles/globals.scss" as *;

.terms-banner {
  @include centered-row;
  align-items: start;
  background-color: var(--color-gray-900);
  border-radius: 16px;
  bottom: 100%;
  gap: 8px;
  justify-content: space-between;
  left: 0;
  margin: 0 auto 8px auto;
  padding: 16px;
  position: absolute;
  right: 0;
  width: calc(100% - 16px);
  z-index: 999;

  @include desktop {
    align-items: center;
    bottom: 8px;
    justify-content: center;
    max-width: max-content;
  }

  & > svg {
    color: var(--color-gray-600);
    min-width: 24px;
  }

  &-content {
    @include centered-row;
    margin: auto 0;

    &-text {
      @include text-roboto-body-400;
      color: var(--color-gray-400);
      text-wrap: pretty;
    }

    &-link {
      @include button-ghost;
      display: inline;
    }
  }

  &-button {
    @include button-secondary;
    background-color: var(--color-gray-800);
    height: auto;
    justify-content: center;
    margin-left: 8px;
    padding: 0;
  }
}
