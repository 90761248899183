@use "src/styles/globals.scss" as *;

.metamask-btn {
  & > button {
    @include button-secondary;
    @include centered-row;
  }

  &-tooltip {
    @include centered-column;
    @include text-roboto-body-400;
    color: var(--color-white);
    gap: 4px;

    & > span {
      @include centered-row;
      gap: 8px;

      & > .icon {
        @include centered-row;

        &.copy {
          cursor: pointer;
        }
      }
    }
  }
}
