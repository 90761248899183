@use "src/styles/globals.scss" as *;

.switch {
  @include centered-row;
  gap: 19px;

  &-label {
    @include centered-row;
    @include text-heading6;
    color: var(--color-white);
    gap: 10px;

    &-indicator {
      @include centered-row;
      background-color: var(--color-gray-20);
      border-radius: 50%;
      height: 24px;
      justify-content: center;
      width: 24px;

      &-dot {
        background-color: var(--color-gray-200);
        border-radius: 50%;
        height: 8px;
        width: 8px;
      }

      &.active {
        background-color: var(--color-plum-20);

        & .switch-label-indicator-dot {
          animation: pulse 1.5s infinite;
          background-color: var(--color-plum);
        }
      }
    }
  }

  &-root {
    all: unset;
    background-color: var(--color-gray-800);
    border-radius: 9999px;
    cursor: pointer;
    height: 24px;
    position: relative;
    transition: background-color 0.2s;
    width: 44px;

    &[data-state="checked"] {
      background-color: var(--color-plum-600);
    }

    &-thumb {
      background-color: var(--color-gray-100);
      border-radius: 9999px;
      display: block;
      height: 18px;
      transform: translateX(3px);
      transition: transform 0.2s;
      width: 18px;

      &[data-state="checked"] {
        transform: translateX(23px);
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
