@use "src/styles/globals.scss" as *;

.banner-message {
  display: flex;
  gap: 12px;
  height: 102px;
  text-wrap: pretty;

  &-bg {
    background: linear-gradient(180deg, var(--color-coral-40) 0%, var(--color-black-20) 100%);
    height: inherit;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-content {
    @include centered-row;
    gap: 16px;
    margin: 16px 0 auto 0;
    z-index: 1;

    @include desktop {
      margin-top: 16px;
    }

    &-icon {
      align-items: center;
      background-color: var(--color-coral-20);
      border-radius: 50%;
      display: flex;
      height: 34px;
      justify-content: center;
      margin-bottom: auto;
      min-width: 34px;

      &-dot {
        background-color: var(--color-coral);
        border-radius: 50%;
        height: 8px;
        width: 8px;
      }
    }

    &-text {
      @include text-heading6;
      color: var(--color-white);
      text-transform: uppercase;

      a.navlink {
        outline: 0;
        color: inherit;
        text-decoration: none;
      }

      &-link {
        color: var(--color-coral);
        text-decoration: underline;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}
